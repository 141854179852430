<template>
	<div>
		<div id="ktcsChatbotContents" data-domain="__d2870012371" data-channel="WEBCHAT" data-user="sbizcenter" data-context="https://qs.hiqri.ai"
         data-interface="wss://qsif.hiqri.ai/sbizcenter/nlu/chat" data-imagepath="https://qs.hiqri.ai"></div>
	</div>
</template>

<script>
export default {
	name: 'ChatBot',
	components: {},
	data: () => ({}),
	computed: {},
	watch: {},
	created() {},
	mounted() {
		this.$nextTick(() => {
			this.initChatBot();
		});
	},
	methods: {
		initChatBot() {
			document.querySelector('html').setAttribute('style', 'overflow: hidden');
			let demo = document.getElementById('ktcsChatbotContents');

			let timeString = new Date().valueOf();
			let url = demo.dataset.context + '/example/webChat.html?' + timeString + '&domain=' + demo.dataset.domain + '&user=' + demo.dataset.user + '&channel=' + demo.dataset.channel + '&interface=' + demo.dataset.interface + '&imagepath=' + demo.dataset.imagepath;
			demo.innerHTML = "<iframe allow='autoplay' id='ktcsChatbotIframe' frameBorder='0' src='" + url + "' style='width:100%;height:100vh;'/>";

			$('#ktcsChatbotIframe').attr('style', 'position: relative; ' + 'right: 0; ' + 'top: 0; ' + 'width: 100%; ' + 'height: 100vh; ' + 'z-index: 20; ' + 'margin: 0; ' + 'padding: 0; ' + 'overflow: hidden;');
		},
		receiveMessage(event) {
			if (event.data === 'close') {
				demo.innerHTML = '';
				window.close();
			}
		},
	},
};
</script>
